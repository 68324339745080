import React from 'react';
import Content from '@/components/Content';
import { SimpleImage } from '@/components/Ads';
import { useTranslation } from 'react-i18next';

const DescriptionSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <div>
        <h2>
          {t(`descriptionSectionTitle1-1`)}
          <br />
          {t(`descriptionSectionTitle1-2`)}
          <span className="text-red">{t(`descriptionSectionTitle1-3`)}</span>
          {t(`descriptionSectionTitle1-4`)}
        </h2>

        <p>{t(`descriptionSectionText1`)}</p>
        <br />
        <p>{t(`descriptionSectionText2`)}</p>
        <br />
        <p>
          {t(`descriptionSectionText3-1`)}
          <strong>
            <u>{t(`descriptionSectionText3-2`)}</u>
          </strong>
          .
        </p>
        <br />
        <p>{t(`descriptionSectionText3-3`)}</p>
      </div>

      <SimpleImage
        source="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Bugaboo/cSwZwtZY/banner.webp"
        sourceMobile="https://la-fraise-ads.s3.eu-west-3.amazonaws.com/Bugaboo/cSwZwtZY/block.webp"
        alt="Bugaboo Ads"
        link="https://campaign.lafraise.app/ZFK0"
      />

      <div className="mt-20">
        <h2>
          {t(`descriptionSectionTitle2-1`)}
          <span className="text-red">{t(`descriptionSectionTitle2-2`)}</span>.
        </h2>

        <p>
          {t(`descriptionSectionText4-1`)}
          <strong>{t(`descriptionSectionText4-2`)}</strong>
          {t(`descriptionSectionText4-3`)}
          <br />
          <br />
          {t(`descriptionSectionText4-4`)}
        </p>
      </div>
    </Content>
  );
};

export default DescriptionSection;
