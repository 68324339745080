import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import Content from '@/components/Content';

const OurPartnersSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <div>
        <h2 className="mb-12 text-center">{t(`partnersTitle`)}</h2>

        <div className="text-center">
          <a
            href="https://www.joone.fr/"
            target="_blank"
            rel="noreferrer"
            className="mr-8"
          >
            <StaticImage
              src="../../../assets/joone.webp"
              alt="Logo Joone"
              className="lg:mr-10 lg:max-w-[200px] max-w-[120px] h-auto mb-6 lg:mb-0"
            />
          </a>

          <a
            href="https://www.terredemamans.com/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../../../assets/terre-de-mamans.webp"
              alt="Logo Terre de Mamans"
            />
          </a>
        </div>
      </div>
    </Content>
  );
};

export default OurPartnersSection;
