import React from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import SearchBar from '@/components/SearchBar';

interface HeroProps {
  source: ImageDataLike;
  alt: string;
  placeholder: string;
}

const Hero: React.FC<HeroProps> = ({ source, alt, placeholder }) => (
  <div className="grid relative mb-12 xl:h-[calc(100vh_-_88px)] py-36 xl:py-0">
    <GatsbyImage
      image={getImage(source)}
      alt={alt}
      className="!absolute inset-0"
    />
    <div className="!absolute inset-0 bg-[#00000055]" />

    <div
      className="relative place-items-center grid"
      style={{
        gridArea: `1/1`,
      }}
    >
      <SearchBar placeholder={placeholder} />
    </div>
  </div>
);

export default Hero;
